import GET_ARTICLES from "../class/getArticles";

const [w,d] = [window,document];

class BLOCKS {
  constructor () {
    this.guidelines('.recruit-guideline__list[data-plugin="getArticles"]');
  }
  guidelines (selector) {
    const getArticles = new GET_ARTICLES(selector);
    getArticles.makeItem = item => `
      <a href="${item.href}" class="recruit-guideline__item">
        <div class="recruit-guideline__item-img"><img src="${item.thumb}" alt="${item.title}" class="img" width="370"></div>
        <!-- /.recruit-guideline__item-img -->
        <div class="recruit-guideline__item-body">
          <span class="cat">${item.category.name}</span>
          <p class="title">${item.title.replace(/(【募集中】)/,'<span class="is-active">募集中</span>')}</p>
          <p class="desc">${item.desc.str_truncate(50)}</p>
          <!-- /.desc -->
        </div>
        <!-- /.recruit-guideline__item-body -->
      </a>
      <!-- /.recruit-guideline__item -->
    `;
    getArticles.render().then( ([[art]]) => {
      if( art.length < 1 ) {
        d.querySelectorAll( selector ).forEach( obj => {
          obj.innerHTML = `<p class="no-entry"><span>現在募集は行っておりません</span></p>`;
          obj.parentElement.querySelector('.recruit-guideline__btnwrap').remove(); //エントリーボタン削除
        });
      }
    });
  }
}

const domReady = TPL => {
  if( TPL.is_list() ) new BLOCKS();
};

const jqInit = () => {};

export default function RECRUIT () {
  // テンプレートが用意したDOMContentLoaded（テンプレートインスタンスが引数に入る）
  d.addEventListener('rwd002.beforeDomready', e => domReady(e.detail) );
  $(() => jqInit());
}