import Swiper from "swiper/bundle";
import GET_ARTICLES from "../class/getArticles";

const [w,d,mq] = [window,document,window.matchMedia( "(max-width: 900px)" ) ];

class BLOCKS {
  constructor() {
    this.ticker('.mainvisual-ticker__body[data-plugin="getArticles"]');
    this.news('.block-news__list[data-plugin="getArticles"]');
    this.technology();
  }

  ticker (selector) {
    const getArticle = new GET_ARTICLES(selector);
    getArticle.makeItem = item => `
      <a href="${item.href}" class="mainvisual-ticker__link">
        <span class="cate">${item.category.name}</span><span class="date">${item.date}</span>
        <strong class="title"><span>${item.title}</span></strong>
      </a>`;
    getArticle.render().then( ([[art,cat]]) => {
      if( art.length < 1 ) {
        d.querySelectorAll( selector ).forEach( obj => {
          let parent = obj.dataset.parent? obj.closest( obj.dataset.parent ): '';
          if(parent) parent.remove();
        });
      }
    });
  }

  technology () {
    const getArticles = this.fetchArticles({
      content: 'technology',
      post_per_page: 10
    });
    const appendSlide = (item,slider) => {
      slider.appendSlide([`
        <a href="${item.href}" class="block-technology__item swiper-slide">
          <img src="${item.thumb}" alt="${item.title}" class="block-technology__item-img" width="417" height="418">
          <div class="block-technology__item-body">
            <span class="title">${item.title}</span>
            <span class="desc">${item.desc.str_truncate(40)}</span>
          </div>
          <!-- /.block-technology__item-body -->
        </a>
        <!-- /.block-technology__item -->
      `]);
    }
    const slider = new Swiper('.block-technology__list', {
      grabCursor: true,
      slidesPerView: "auto",
      loop: true,
      spaceBetween: 12,
      breakpoints: {
        900: {
          spaceBetween: 46
        }
      },
      navigation: {
        nextEl: '.block-technology .swiper-button-next',
        prevEl: '.block-technology .swiper-button-prev',
      },
      pagination: {
        el: '.block-technology .swiper-pagination',
        type: 'progressbar',
      },
    });
    slider.removeAllSlides();
    getArticles.then( res => {
      d.querySelector('.block-technology__slider').innerHTML = '';
      res.map( article => appendSlide(article,slider) );
      slider.slideToLoop(0);
    });
  }

  news (selector) {
    const getArticle = new GET_ARTICLES(selector);
    const getCategories = this.fetchCategories('news');
    const categoryContainer = d.querySelectorAll('.block-news [data-plugin="getCategories"]');
    getArticle.makeItem = item => `
      <a href="${item.href}" class="block-news__item">
        <div class="block-news__item-img"><img src="${item.thumb}" alt="${item.title}"></div>
        <!-- /.block-news__item-img -->
        <div class="block-news__item-body">
          <span class="cate">${item.category.name}</span>
          <span class="date">${item.date}</span>
          <span class="title${item.is_new? ' is-new': ''}">${item.title.str_truncate(30)}</span>
        </div>
        <!-- /.block-news__body -->
      </a>`;
    getArticle.render().then( ([[art,cat]]) => {
      if( art.length < 1 ) {
        d.querySelectorAll( selector ).forEach( obj => {
          let parent = obj.dataset.parent? obj.closest( obj.dataset.parent ): '';
          if(parent) parent.remove();
        });
      }
    });
    getCategories.then( cats => {
      let cat = {
        all: {
          id: 'all', href: '/news/?mode=list', name: 'ALL', turn: 0
        },
        other: {
          id: 'other', href: '/news/?mode=list&cat=0', name: 'その他', turn: 99
        }
      }
      cats = [ cat.all, ...cats, cat.other ];
      const makeHTML = cat => `<li class="block-news__categories-item is-${cat.id}"><a href="${cat.href}">${cat.name}</a></li>`;
      categoryContainer.forEach( obj => {
        obj.innerHTML = '';
        cats.forEach( cat => obj.insertAdjacentHTML('beforeend', makeHTML(cat) ) );
      });
    });

  }


  fetchCategories (content) {
    return fetch(`/assets/api/getCategories/?content=${content}`)
      .then(r => r.json())
      .catch(e => { throw new Error(e)});
  }

  fetchArticles (opt){
    return fetch(`/assets/api/getArticles/?${ new URLSearchParams(opt)}`)
    .then(r => r.json())
    .catch(e => { throw new Error(e)});
  }
}

const domReady = TPL => {
  new BLOCKS();
};

const jqInit = () => {
  w.jQuery = $

  /****************************
   * movie background
   */
  $.when(
    $.getScript('/assets/js/vendor/jquery.mb.YTPlayer.min.js')
  ).then( () => {
    const $MV = $('[data-plugin="ytplayer"]')
    const param = id => `{
      videoURL: '${id}',
      containment: '${(mq.matches)? '.mainvisual__wrap':'#allbox'}',
      mute: true,
      showControls: false,
      autoPlay: true,
      useOnMobile: true,
      showYTLogo: false,
      abundance: '${(mq.matches)? 0.01: 0.15}',
      stopMovieOnBlur: false,
      loop: true,
      startAt: 0,
      anchor: 'center,center',
    }`.replace(/\n|\s/g,'')
    $MV.attr('data-property',param('agL0QrC6UPg'));
    // $MV.attr('data-property',param(''));
    $MV.YTPlayer();
    $MV.YTPApplyFilters({
      brightness: 80
    });
    $MV.YTPPlay();
  });
};

export default function HOME () {
  // テンプレートが用意したDOMContentLoaded（テンプレートインスタンスが引数に入る）
  d.addEventListener('rwd002.beforeDomready', e => domReady(e.detail) );
  
  $(() => jqInit());
}