import ScrollObserver from '../class/scrollObserver';

const [ w, d, mq ] = [window, document, window.matchMedia( "(max-width: 900px)" ) ]

export default class pageTopObserver extends ScrollObserver {
  //newしたときに実行される
  constructor(elm=d.querySelector('.rwd002-pagetop__wrap'),opt={}) {
    opt = Object.assign({  
      rootMargin: "0% 0px 0%", // ビューポートの中心を判定基準にする
    }, opt);
    super(opt);
    this.$observeElement = $('<div/>');
    this.$observeElement.css({
      'position':'absolute',
      'top':'0',
      'left':'0',
      'display': 'block',
      'width':'0',
      'height':'100vh',
      'pointer-events': 'none'
    });
    this.$observeElement.appendTo('#allbox');
    this.pagetopBlock = elm;
    this.observeElement()
  }

  observeElement () {
    this.observer = new IntersectionObserver( this.callback.bind(this), this.opt);
    this.observer.observe( this.$observeElement[0] );
  }
  intersectIn ( entry, object ) {
    this.pagetopBlock.classList.add('hidden');
  }
  intersectOut ( entry, object ) {
    this.pagetopBlock.classList.remove('hidden');
  }
}