import Vue from 'vue/dist/vue.esm'
import TAGSORT,{related} from '../class/tagsort';

const [w,d] = [window, document];

const categoriesComponent = {
  template: '#categories-template',
  props: {
    categories: Array
  },

  methods : {
    isCurrent(catID) {
      let param = location.search;
      let cateQuery = /cat=\d+/.test(param) ? parseInt(param.match(/cat=(\d+)/)[1]) : false;
      return cateQuery == catID;
    }
  }
}

const tagComponent = {
  data() {
    return {
      isOpen: false,
      exist_secondary: false,
      tags: [],
      opt: {
        container: '.rwd002-container', // taglistを表示する基準の要素
        minimumTagAmount : 20, // タグの最低表示数
        hideUselessTags : 0, // 最低使用回数
        btnHtml: {
          open: `全てを表示<i class="fa-solid fa-angle-down fa-xs ms-1"></i>`,
          close: `一部のみ表示<i class="fa-solid fa-angle-up fa-xs ms-1"></i>`,
        }
      },
    }
  },
  props: {
    items :Array
  },
  template: "#taglist-template",
  computed: {
    btnHtml () {
      return this.isOpen ? this.opt.btnHtml.close : this.opt.btnHtml.open;
    },
    is_current () {
      return tag => tag == this.getParam('tag');
    },
    is_secondary () {
      return (ind, cnt) => {
        if( ind >= this.opt.minimumTagAmount || cnt < this.opt.hideUselessTags ){
          if( !this.exist_secondary ) this.exist_secondary = true;
          return true;
        }
      };
    }
  },
  methods: {
    getAllTags () {
      return TAGSORT.getTags( this.items )
    },
    tglTaglist () {
      this.isOpen = !this.isOpen;
    },
    // URLパラメータから指定したキーの値を取得する
    getParam ( param ) {
      let params = ( new URL(location) ).searchParams;
      return param? params.get(param) : params;
    }
  }
};

const itemsComponent = {
  template: '#items-template',
  data() {
    return {
      currentTagName: ''
    }
  },
  props: {
    categories: Array,
    items: Array
  },
  methods : {
    filterCat () {
      let param = location.search;
      let cateQuery = /cat=\d+/.test(param) ? parseInt(param.match(/cat=(\d+)/)[1]) : false;
      let tagQuery = /tag=[^&]+?/.test(param) ? param.match(/tag=([^&]+)?/)[1]: false;
      if( cateQuery ) return this.categories.filter( c => c.id == cateQuery );
      if( tagQuery ) {
        this.currentTagName = decodeURI(tagQuery);
        return [{id:'ALL'}];
      }
      return this.categories;
    },
    itemFilter (catID) {
      return (catID != 'ALL')? this.items.filter( v => v.category == catID) : this.tagFilter(this.currentTagName);
    },
    tagFilter (tagname) {
      return this.items.filter( v => v.keywords.includes(tagname));
    }
  },

  filters: {
    truncate : TAGSORT.truncate
  }
}


class related_custom extends related {
  constructor( opt, parent ) {
    super( opt, parent );
    opt.amount = 4;
    this.itemTemplate = `
    <div class="rwd002-related-content" v-if="relatedArticles.length">
      <div class="mate-list-relatedgroup">
        <h3 class="mate-list-relatedgroup__title">関連素材</h3>
        <!-- /.mate-list-relatedgroup__title -->
        <ul class="mate-list-itemlist">
          <li class="mate-list-item" v-for="( item, ind ) in relatedArticles" :key="item.id" :class="[ 'is-item-' + ind ]">
            <a :href="item.href" class="mate-list-item__link" :class="[ 'is-item-' + item.id ]">
              <div class="mate-list-item__thumb"><img :src="item.thumb" :alt="item.title" class="img"></div>
              <!-- /.mate-list-item__thumb -->
              <div class="mate-list-item__body">
                <span class="title">{{ item.title | truncate(30) }}</span>
                <span class="cat"><i class="fa-solid fa-tag"></i>{{ item.category.name }}</span>
                <p class="tags">
                  <span class="tag" v-for="tag in item.keywords">#{{ tag }}</span>
                </p>
              </div>
              <!-- /.mate-list-item__body -->
            </a>
            <!-- /.mate-list-item__link -->
          </li>
          <!-- /.mate-list-item -->
        </ul>
        <!-- /.mate-list-itemlist -->
      </div>
      <!-- /.mate-list-relatedgroup -->
    </div>
    <!-- /.rwd002-related-content -->
  `;
  }
  insertContainer ( tag = `<related />`) {
    return false
  }
}


const domReady = TPL => {

  const tagsort = new TAGSORT();

  if( tagsort.is_list() ){
    new Vue({
      el: "#materials-list",
      components: {
        itemsComponent,
        categoriesComponent,
        tagComponent
      },
      data: {
        items: [],
        categories: [],
      },
      created (){
        let content = 'materials';
        this.fetchCategories(content);
        this.fetchArticles({ content });
      },
      
      methods: {
        fetchCategories (content) {
          fetch(`/assets/api/getCategories/?content=${content}`).then(r => r.json())
          .then(res => this.categories = res||[]);
        },
        fetchArticles (opt){
          fetch(`/assets/api/getArticles/?${ new URLSearchParams(opt)}`).then(r => r.json())
          .then(res => this.items = res);
        }
      }
    });
  }

  
  if ( tagsort.is_detail() ){
    new Vue({
      el: "#materials-detail",
      components: {
        categoriesComponent,
        tagComponent
      },
      data: {
        items: [],
        categories: [],
      },
      created (){
        let content = 'materials';
        this.fetchCategories(content);
        this.fetchArticles({ content });
      },
      
      methods: {
        fetchCategories (content) {
          fetch(`/assets/api/getCategories/?content=${content}`).then(r => r.json())
          .then(res => this.categories = res||[]);
        },
        fetchArticles (opt){
          fetch(`/assets/api/getArticles/?${ new URLSearchParams(opt)}`).then(r => r.json())
          .then(res => this.items = res);
        }
      }
    });
  }

  tagsort.init.then( TAGSORT => {
    tagsort.related = new related_custom( tagsort.opt.related, TAGSORT );
    if( tagsort.is_detail() ) tagsort.related.render();
  });
};

const jqInit = () => {};

export default function MATERIALS () {
  // テンプレートが用意したDOMContentLoaded（テンプレートインスタンスが引数に入る）
  d.addEventListener('rwd002.beforeDomready', e => domReady(e.detail) ); 
  $(() => jqInit());
}