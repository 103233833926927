import Vue from 'vue/dist/vue.esm'
import TAGSORT from '../class/tagsort';

const [w,d] = [window, document];

const categoriesComponent = {
  template: '#categories-template',
  props: {
    categories: Array
  },

  methods : {
    isCurrent(catID) {
      let param = location.search;
      let cateQuery = /cat=\d+?/.test(param) ? parseInt(param.match(/cat=(\d+?)/)[1]) : false;
      return cateQuery == catID;
    }
  }
}


const itemsComponent = {
  template: '#items-template',
  props: {
    categories: Array,
    items: Array
  },
  methods : {
    filterCat () {
      let param = location.search;
      let cateQuery = /cat=\d+?/.test(param) ? parseInt(param.match(/cat=(\d+?)/)[1]) : false;
      return cateQuery? this.categories.filter( c => c.id == cateQuery ) : this.categories;
    },
    catFilter (catID) {
      return this.items.filter( v => v.category == catID);
    } 
  },

  filters: {
    truncate : TAGSORT.truncate
  }
}


const domReady = TPL => {
  const tagsort = new TAGSORT();

  if( tagsort.is_list() ){
    const list = new Vue({
      el: "#technology-list",
      components: {
        itemsComponent,
        categoriesComponent
      },
      data: {
        items: [],
        categories: [],
      },
      created (){
        let content = 'technology';
        this.fetchCategories(content);
        this.fetchArticles({ content });
      },
      
      methods: {
        fetchCategories (content) {
          fetch(`/assets/api/getCategories/?content=${content}`).then(r => r.json())
          .then(res => this.categories = res||[]);
        },
        fetchArticles (opt){
          fetch(`/assets/api/getArticles/?${ new URLSearchParams(opt)}`).then(r => r.json())
          .then(res => this.items = res);
        }
      }
    });
  }
  
  if ( tagsort.is_detail() ){
    new Vue({
      el: "#technology-detail",
      components: {
        categoriesComponent,
      },
      data: {
        categories: [],
      },
      created (){
        let content = 'technology';
        this.fetchCategories(content);
      },
      
      methods: {
        fetchCategories (content) {
          fetch(`/assets/api/getCategories/?content=${content}`).then(r => r.json())
          .then(res => this.categories = res||[]);
        }
      }
    });
  }
};

const jqInit = () => {};

export default function TECHNOLOGY () {
  // テンプレートが用意したDOMContentLoaded（テンプレートインスタンスが引数に入る）
  d.addEventListener('rwd002.beforeDomready', e => domReady(e.detail) ); 
  $(() => jqInit());
}